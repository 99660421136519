body {
  background-color: #293742;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Open Sans, Helvetica Neue, Icons16, sans-serif;
}

.loading-indicator {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: #a7b6c2;
  animation-name: flash-lightly;
  animation-timing-function: linear;
  animation-duration: 1000ms;
  animation-direction: alternate-reverse;
  animation-iteration-count: infinite;
  opacity: 0.01;

  &.loading-indicator-loaded {
    opacity: 1;
    transition: opacity 3000ms 500ms;
  }
}

@keyframes flash-lightly {
  from {
    color: #a7b6c2;
  }

  to {
    color: #f5f8fa;
  }
}

.visit-button {
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4),
    inset 0 -1px 0 rgba(16, 22, 26, 0.2);
  background-color: #137cbd;
  background-image: linear-gradient(
    180deg,
    hsla(0, 0%, 100%, 0.1),
    hsla(0, 0%, 100%, 0)
  );
  color: #fff;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  padding: 0 15px;
  vertical-align: middle;
  min-width: 40px;
  min-height: 40px;
  line-height: 40px;
  letter-spacing: 0;
  font-weight: 400;
  font-size: 16px;

  position: absolute;
  right: 20px;
  top: 20px;

  opacity: 0.01;
  transition: opacity 500ms;

  &.visit-button-loaded {
    opacity: 1;
  }

  &:hover {
    background-color: #106ba3;

    &:active {
      background-color: #0e5a8a;
    }
  }
}
